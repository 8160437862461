import React, { FC } from "react";
import { Path } from "react-native-svg";

//const Up = (
//  <Path d="M 22.5,30 22.5,37 22.5,37 M 22.5,30 20,32.5 M 22.5,30 25,32.5" />
//);

//const Down = (
//  <Path d="M 22.5,30 22.5,37 22.5,37 M 22.5,37 20,34.5 M 22.5,37 25,34.5" />
//);

const Pawn: FC = () => {
  return (
    <>
      <Path
        x={1}
        d="M 22.5,9 C 20.29,9 18.5,10.79 18.5,13 C 18.5,13.89 18.79,14.71 19.28,15.38 C 17.33,16.5 16.0,18.59 16.0,21 C 16.0,23.03 16.94,24.84 18.41,26.03 C 15.41,27.09 11.0,31.58 11.0,39.5 L 34.0,39.5 C 34.0,31.58 29.5,27.09 26.5,26.03 C 28.06,24.84 29.0,23.03 29.0,21 C 29.0,18.59 27.67,16.5 25.72,15.38 C 26.21,14.71 26.5,13.89 26.5,13 C 26.5,10.79 24.71,9 22.5,9 z "
      />
    </>
  );
};

export { Pawn };
