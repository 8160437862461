export enum Direction {
  N,
  S,
  E,
  W,
  NE,
  SE,
  SW,
  NW,
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  H7,
  H8,
  H9,
  H10,
  H11,
  H12,
  Down,
}
