import Color from "color";

export const Colors = {
  WHITE: Color("#FFFFFF"),
  BLACK: Color("#161616"),
  RED: Color("#FF0000"),
  TRANSPARENT: Color().fade(1),
  TEXT: {
    DARK: Color("#212121"),
    LIGHT: Color("#FFFFFF").fade(0.1),
    LIGHT_SECONDARY: Color("#FFFFFF").fade(0.3),
  },
  EMPHATIC: {
    DARK: Color("#000000"),
    LIGHT: Color("#FFFFFF"),
  },
  GREY: Color("#888888"),
  DARKEST: Color("#212121"),
  DARKER: Color("#2A2A2A"),
  DARK: Color("#333333"),
  DARKISH: Color("#4C4C4C"),
  INFO: Color("#4696EC"),
  SUCCESS: Color("#67AC5B"),
  WARNING: Color("#F29C38"),
  ERROR: Color("#E25141"),
  HIGHLIGHT: {
    INFO: Color("#007BFF"),
    SUCCESS: Color("#26EF10"),
    WARNING_LIGHT: Color("#FFD500"),
    WARNING: Color("#FF8800"),
    ERROR: Color("#FF1900"),
  },
  TRAIT: {
    MOVEMENT: Color("#00CCFF"),
    BOARD: Color("#0064FD"),
    REACTION: Color("#FF5D00"),
    RESTRICTION: Color("#DF2000"),
    ENDING: Color("#D4AF37"),
    SIMULATION: Color("#8C51FC"),
  },
  PLAYER: [
    Color("#FCFCFC"),
    Color("#606060"),
    Color("#B4B4BF"),
    Color("#D9BB6F"),
    Color("#6060FF"),
    Color("#FF6060"),
    Color("#E6E625"),
    Color("#60FF60"),
  ],
  SQUARE: [Color("#A4B9CC"), Color("#E8E5E5"), Color("#C9D1DA")],
  MCHESS_ORANGE: Color("#F26100"),
  MCHESS_BLUE: Color("#99caf7"),
  DISCORD: Color("#7289DA"),
  LIGHTING: {
    WARM: Color("#FFCCCC"),
    NEUTRAL: Color("#E5CCE5"),
    COLD: Color("#CCCCFF"),
  },
};
