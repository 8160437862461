import React, { FC } from "react";
import { Svg, Path } from "react-native-svg";
import { Colors } from "primitives/Colors";

interface Props {
  color?: string;
  size?: number;
}

export const NoTimerIcon: FC<Props> = ({
  color = Colors.TEXT.LIGHT_SECONDARY.toString(),
  size = 24,
}) => {
  return (
    <Svg width={size} height={size} viewBox="0 0 24 24">
      <Path d="M0 0h24v24H0V0z" fill="transparent" />
      <Path
        d="M11 8v.86l2 2V8zM9 1h6v2H9zm3 5c3.87 0 7 3.13 7 7 0 1.12-.27 2.18-.74 3.12l1.47 1.47C20.53 16.25 21 14.68 21 13c0-2.12-.74-4.07-1.97-5.61l1.42-1.42c-.43-.51-.9-.99-1.41-1.41l-1.42 1.42C16.07 4.74 14.12 4 12 4c-1.68 0-3.25.47-4.59 1.27l1.47 1.47c.94-.47 2-.74 3.12-.74zM3.16 3.86L1.75 5.27 4.5 8.02C3.56 9.45 3 11.16 3 13c0 4.97 4.02 9 9 9 1.84 0 3.55-.55 4.98-1.5l2.5 2.5 1.41-1.41L3.16 3.86zM12 20c-3.87 0-7-3.13-7-7 0-1.29.35-2.49.96-3.52l9.57 9.57c-1.04.6-2.24.95-3.53.95z"
        fill={color}
      />
    </Svg>
  );
};
